
import { defineComponent, ref, computed } from "vue";
import { dateFormat, getFirstXLetters, gaEvent } from "@/utils/format";
import { useStore } from "vuex";
import Tooltip from "@/components/common/tooltip.vue";
export default defineComponent({
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
    host: {
      type: String,
      default: "whatsapp",
    },
  },
  setup() {
    const store = useStore();
    const isVibe = computed(() => store.state.isVibe);
    return {
      isVibe,
      dateFormat,
      getFirstXLetters,
    };
  },
});
